import { message } from "antd";

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const delayedLogout = () => {
  setTimeout(() => {
    window.location.assign("/");
  }, 2000);
};

export const QualityColor = (score: number) => {
  if (score >= 0 && score < 30) {
    return "#ef4c07";
  } else if (score >= 30 && score < 40) {
    return "coral";
  } else if (score >= 40 && score < 50) {
    return "orange";
  } else if (score >= 50 && score < 65) {
    return "#ead702";
  } else if (score >= 65 && score <= 100) {
    return "#04cc46";
  }
};

export const MediumDateStyle = new Intl.DateTimeFormat("en", {
  dateStyle: "medium",
});

export const FormatMediumDateStyle = (dateStr: string): string => {
  const dt = GetDate(dateStr);
  if (dt) {
    return MediumDateStyle.format(dt);
  }
  return "";
};

export const FormatUnixMediumDateStyle = (unixDate: number): string => {
  if (unixDate) {
    const dt = new Date(unixDate * 1000);
    if (dt) {
      return MediumDateStyle.format(dt);
    }
  }
  return "";
};

export const GetDate = (dateStr: string): Date | undefined => {
  const dt = Date.parse(dateStr);
  if (dt) {
    return new Date(dt);
  }
  return undefined;
};

export const AddKey = <T>(arr: Array<Record<string, unknown>>): T => {
  arr.every((item, idx) => (item.key = idx + 1));
  return arr as T;
};

export const ScrollTo = (ele: HTMLElement | null) => {
  if (ele) {
    const y = ele.getBoundingClientRect().top + window.pageYOffset + -65;
    window.scrollTo({ top: y, behavior: "smooth" });
  }
};

export const isValidEmail = (email: string): boolean => {
  return emailRegex.test(email);
};

export const windowLocation = (url: string) => {
  window.location.href = url;
};

export const logout = (redirect = true) => {
  console.log("removing token 1");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("rt");
  localStorage.removeItem("userid");
  localStorage.removeItem("User_Type");
  localStorage.removeItem("userName");
  if (redirect) {
    window.location.href = "/login";
  }
};

export const snackBarNotification = (
  dis_message: string,
  type: string,
  time = 5
) => {
  if (type === "success") {
    message.success(dis_message, time);
  } else if (type === "error") {
    message.error(dis_message, time);
  } else if (type === "warning") {
    message.warning(dis_message, time);
  } else {
    message.info(dis_message, time);
  }
};

export const isBrowerSupported = (): boolean => {
  // if (navigator.userAgent.includes("Chrome")) {
  //   return true;
  // } else if (navigator.userAgent.includes("Safari")) {
  //   return true;
  // }
  return true;
};

export const resetScroll = () => {
  window.scrollTo(0, 0);
};

export const getTrialColor = (score: number) => {
  if (score <= 29) {
    return "#EF4C07";
  } else if (score > 29 && score < 39) {
    return "#FF7F50";
  } else if (score > 39 && score <= 49) {
    return "#FFA500";
  } else if (score > 49 && score <= 64) {
    return "#EAD702";
  } else if (score > 64) {
    return "#13E341";
  }
  return "#d9d9d9";
};

export const getStatColor = (type: string) => {
  switch (type) {
    case "inc":
      return "#00AE46";
    case "dec":
      return "#FB460E";
    default:
      return "#939393";
  }
};

const DISPLAY_DATE: Intl.DateTimeFormatOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

export const getDisplayDate = (dateStr: string): string => {
  if (dateStr) {
    try {
      const displayDate = new Date(dateStr).toLocaleDateString(
        "en-IN",
        DISPLAY_DATE
      );
      if (displayDate !== "Invalid Date") {
        return displayDate;
      }
    } catch {}
  }

  return "-";
};

const DATEOPTIONS: Intl.DateTimeFormatOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  timeZoneName: "shortGeneric",
};

export const now = () => {
  return new Date().toLocaleDateString("en-US", DATEOPTIONS);
};

export const formatNumber = (num: number) => {
  return num.toLocaleString("en-IN");
};
